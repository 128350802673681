.root {
  padding-top: 7rem; /* Assuming rem() function converts to rem value */
  padding-bottom: 7.5rem; /* Assuming rem() function converts to rem value */
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #f8dfd4;
  height: 100vh;
  width: 100vw;
}

.label {
  text-align: center;
  font-weight: 900;
  font-size: 140px;
  line-height: 1;
  color: #3081d0;
}

.title {
  font-family: "Greycliff CF";
  text-align: center;
  font-weight: 900;
  font-size: 2rem; /* Assuming rem() function converts to rem value */
}

@media (max-width: 640px) {
  .label {
    text-align: center;
    font-weight: 700;
    font-size: 50px;
    line-height: 1;
    color: #3081d0;
  }

  .title {
    font-family: "Greycliff CF";
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem; /* Assuming rem() function converts to rem value */
  }
}
